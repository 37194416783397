body{
  font-family: 'Montserrat', sans-serif;
}
.App {
  text-align: center;
  margin-top: 40px;
  background-color: #ecf2f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header{
  position: fixed;
  z-index: 5;
  top: 0;
  width: 100%;
  background-color: white;
  border-bottom-width: 1px;
  border-bottom-color: rgb(185, 185, 185);
  border-bottom-style: solid;
}

.header-logo{
  height: 40px;
  cursor: pointer;
}

.page-container{
  display: flex;
  max-width: 1600px;
  margin: auto;
  min-height: 90%;
  height: 90%;
  min-width: 80%;
}

.filter-container{
  margin-top: 20px;
}

.filter{
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
  background-color: white;
  margin: 20px 20px;
  text-align: start;
  border-radius: 10px;
}

.filter-label{
  font-weight: bold;
  margin-top: 10px;
}

.filter-input{
  padding: 5px;
  font-weight: bold;
  outline: none;
  border: 1px solid gainsboro;
  transition: ease-in 0.5s;
  margin-bottom: 10px;
}

.filter-input:focus{
  border-color: aqua;
}

.filter-button{
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  background-color: darkblue;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: ease-in 0.2s;
}

.filter-button:hover{
  background-color: rgb(88, 88, 184);
}

.result-container {
  max-width: 1500px;
  min-height: 90%;
  margin: auto;
}

.articles-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}

.pagination{
  display: flex;
  list-style-type: none;
  justify-content: center;
  flex-wrap: wrap;
}

.pagination-number{
  font-size: 16px;
  color: darkblue;
  background-color: white;
  padding: 10px 24px;
  margin-left: 1px;
  border-width: .5px;
  border-color: darkblue;
  border-radius: .2px;
  border-style: solid;
  cursor: pointer;
}

.pagination-number:hover{
  background-color: rgb(215, 209, 250);
}

.pagination-more{
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: white;
  background-color: rgb(15, 184, 161);
  outline: none;
  border: none;
  border-radius: 2px;
  padding: 10px;
  margin: 0px 20px;
  cursor: pointer;
  transition: ease-in 0.2s;
}

.pagination-more:hover{
  background-color: rgb(104, 226, 210);
}

.active-page{
  color: white;
  background-color: darkblue;
}

.article{
  margin: 20px 20px;
  background-color: white;
  aspect-ratio: 1/1;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 2px 10px rgb(197, 197, 197);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.article:hover{
  transform: scale(1.05);
}

@media only screen and (max-width: 2000px) {
  .article{
    width: 340px;
  }
}

@media only screen and (max-width: 1100px) {
  .article{
    width: 300px;
  }

  .article-title{
    font-size: small;
  }
  .article-section-text{
    font-size: small;
  }
}

@media only screen and (max-width: 1016px) {
  .article{
    width: 260px;
    aspect-ratio: 1/1.2;
  }
}

@media only screen and (max-width: 936px) {
  .page-container{
    display: block;
    padding-top: 40px;
  }
}

.article-image{
  width: 100%;
  height: 70%;
  object-fit: cover;
}

.article-description{
  display: flex;
  flex-direction: column;
  justify-content: space-between  ;
  height: 30%;
}

.article-title{
  padding: 0px 10px;
  font-weight: 500;
}

.article-section{
  display: flex;
  padding: 10px;
  justify-content: flex-end;
}

.article-section-text{
  padding: 0px 2px;
  border-left: darkblue  solid;
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
  color: rgb(107, 107, 107);
}

.no-response{
  justify-self: flex-start;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  font-weight: 500;
}

.alert{
  color: white;
  background-color: gold;
  font-weight: 500;
  padding: 20px;
  animation: grow cubic-bezier(0.075, 0.82, 0.165, 1) 1s ;
}

@keyframes grow{
  from{
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding: 0px;
  }
  to{
    height: auto;
    opacity: 1;
    overflow: visible;
  }
}

.alert-hide{
  animation: shrink cubic-bezier(0.075, 0.82, 0.165, 1) 1s ;
}

@keyframes shrink {
  to{
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
}

.footer{
  border-top: darkblue solid 1px;
  bottom: 0;
  padding: 20px;
  font-weight: 500;
  background-color: white;
}